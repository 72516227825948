<template>
  <div>
    <v-card class="transparent" flat>
      <v-card-title> التقارير </v-card-title>
      <v-card-subtitle>
        اختر الحقل لرؤية جميع التقارير الخاصة به
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-container fluid>
        <v-card class="mt-4 px-5" outlined>
          <v-row align="center">
            <v-col cols="4">
              <h3 class="mb-2">اختر الحقل <span class="required">*</span></h3>
              <v-autocomplete
                clearable
                v-model="selectedField"
                item-text="name"
                item-value="id"
                :items="$global.state.fields"
                class="mr-1"
                placeholder="الحقول"
                :loading="$global.state.loading"
                :no-data-text="$t('no-data')"
                hide-details="auto"
                outlined=""
                filled
                return-object
                @change="$forceUpdate()"
              />
            </v-col>
            <v-col cols="4">
              <h3 class="mb-2">اختر النود <span class="required">*</span></h3>
              <v-autocomplete
                clearable
                v-model="selectedNode"
                item-text="name"
                item-value="id"
                :items="$global.state.nodes"
                class="mr-1"
                placeholder="النود"
                :loading="$global.state.loading"
                :no-data-text="$t('no-data')"
                hide-details="auto"
                outlined=""
                filled
                :disabled="!selectedField"
                @change="$forceUpdate()"
              />
            </v-col>
            <v-col
              cols="5"
              v-if="
                selectedField != null &&
                selectedNode != null &&
                selectedField.fieldType.name != 'Table'
              "
            >
              <h3 class="mb-2">
                ادخال قيمة للبحث عنها <span class="required">*</span>
              </h3>
              <div>
                <v-text-field
                  v-model="options.Data"
                  label="ادخل قيمة للبحث عنها"
                  outlined
                  required
                  filled
                  hide-details="auto"
                />
              </div>
            </v-col>
            <v-col
              cols="12"
              v-if="
                selectedField != null &&
                selectedNode != null &&
                selectedField.fieldType.name == 'Table'
              "
            >
              <h3 class="mb-2">حدد العواميد التي تريد البحث عنهم</h3>
              <v-row>
                <v-col
                  v-for="(query, i) in tableHeaders"
                  :key="query.value"
                  cols="2"
                >
                  <v-checkbox
                    :label="query.text"
                    :value="query.value"
                    v-model="tableHeaders[i].checked"
                  ></v-checkbox>
                  <v-text-field
                    v-if="tableHeaders[i].checked"
                    :label="$t('bhth')"
                    outlined
                    filled
                    v-model="tableHeaders[i].search"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4" v-if="selectedNode == null">
              <h3 class="mb-2">
                ادخال قيمة للبحث عنها <span class="required">*</span>
              </h3>
              <h4>يجب ان تقوم بأختيار الحقل اولاً</h4>
            </v-col>
            <v-col cols="12">
              <div class="d-flex justify-end align-center mt-8">
                <v-btn
                  :disabled="selectedField == null"
                  @click="getResults"
                  color="success"
                  large
                >
                  <v-icon>search</v-icon>
                  بحث
                </v-btn>
                <v-btn
                  class="mr-1"
                  color="error"
                  @click="
                    () => {
                      selectedField = null;
                      options.Data = null;
                      options.WorkflowId = null;
                      selectWorkFlow = false;
                      selectedNode = null;
                    }
                  "
                  large
                >
                  <v-icon>mdi-restart</v-icon>
                </v-btn>
              </div>
            </v-col>
            <!-- <v-col cols="12">
              <v-checkbox
                label="اختيار المعاملة"
                v-model="selectWorkFlow"
                :value="true"
              ></v-checkbox>
              <v-autocomplete
                v-if="selectWorkFlow"
                clearable
                v-model="options.WorkflowId"
                item-text="name"
                item-value="id"
                :items="$global.state.workflows"
                class="mr-1"
                :label="$t('nwa-almaamlh')"
                :loading="$global.state.loading"
                :no-data-text="$t('no-data')"
                hide-details=""
                prepend-inner-icon="date_range"
                outlined=""
                filled=""
              />
            </v-col> -->
          </v-row>
        </v-card>
        <v-card outlined class="mt-10">
          <v-card-title> النتائج ( {{tableItems.length}} )</v-card-title>
          <v-divider></v-divider>
          <div v-if="selectedField != null">
            <v-card-text v-if="selectedField.fieldType.name != 'Table'">
              <div
                v-if="selectedField == null"
                class="justify-center d-flex align-center text-center"
              >
                <Loading v-if="$global.state.loading" />
                <h3 v-if="!$global.state.loading">
                  قم بأدخال نوع الحقل والقيمة للبحث عنها
                </h3>
              </div>

              <div v-else>
                <div class="d-flex">
                  <h2 class="black--text ml-4">
                    عدد النتائج : {{ results.length }}
                  </h2>
                  <v-divider vertical></v-divider>
                  <h2 class="black--text mx-4">
                    عدد المكتملة :
                    {{ results.filter((v) => v.orderNode.isComplete).length }}
                  </h2>
                  <v-divider vertical></v-divider>
                  <h2 class="black--text mr-4">
                    عدد الغير المكتملة :
                    {{ results.filter((v) => !v.orderNode.isComplete).length }}
                  </h2>
                </div>
                <v-data-table
                  class="mt-1"
                  :headers="headers"
                  :items="results"
                  :no-data-text="$t('no-data')"
                  hide-default-footer
                >
                  <template v-slot:item.created="{ item }">
                    <div class="grey--text">
                      {{
                        new Date(item.created).toISOString().split("T")[0]
                      }},<span>{{
                        new Date(item.created).toLocaleString().split(",")[1]
                      }}</span>
                    </div>
                  </template>
                  <template v-slot:item.orderNode.isComplete="{ item }">
                    {{ item.orderNode.isComplete ? "اكتمال" : "غير مكتمل" }}
                  </template>
                </v-data-table>
              </div>
            </v-card-text>
            <v-card-text v-else>
              <v-data-table
                :headers="tableHeaders"
                :items="tableItems"
                hide-default-footer
                :loading="this.$global.state.loading"
                no-data-text="لا يوجد بيانات"
                :options="{ itemsPerPage: 1000 }"
              >
              </v-data-table>
            </v-card-text>
          </div>
        </v-card>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import Loading from "../../components/Loading.vue";
export default {
  name: "reports",
  components: {
    Loading,
  },

  data() {
    return {
      selectedField: null,
      selectedValue: null,
      selectedNode: null,
      selectWorkFlow: false,
      workflowId: "",
      tableItems: [],
      queries: [],
      options: {
        NodeId: null,
        FieldId: null,
        Data: null,
        PageSize: 1000,
        WorkflowId: null,
      },
      results: [],
      headers: [
        { text: "المحتوى", value: "data" },
        {
          text: "المستخدم",
          value: "orderNode.user.fullName",
        },
        {
          text: this.$t('alhalh'),
          value: "orderNode.isComplete",
        },
        {
          text: "التاريخ",
          value: "created",
        },
      ],
      tableHeaders: [],
    };
  },
  methods: {
    setTableHeaders(field) {
      for (let i = 0; i < field.fieldChildren.length; i++) {
        if (
          this.tableHeaders.filter((v) => v.value == field.fieldChildren[i].id)
            .length == 0
        ) {
          this.tableHeaders.push({
            text: field.fieldChildren[i].field.name,
            value: field.fieldChildren[i].id,
            sequence: field.fieldChildren[i].sequence,
            search: "",
            checked: false,
          });
        }
      }
      this.tableHeaders.sort((a, b) => a.sequence - b.sequence);
    },
    async getTableItems() {
      this.$global.state.loading = true;
      let query = "";
      for (let i = 0; i < this.tableHeaders.length; i++) {
        let qq = this.tableHeaders[i];
        if (qq.checked) {
          query += `&Queries=${qq.value}@${qq.search}`;
        }
      }
      let items = (
        await this.$http.get("/OrderDataChild?pageSize=1000" + query, {
          params: {
            FieldId: this.selectedField.id,
            PageSize: 10000,
            NodeId: this.selectedNode,
          },
        })
      ).data.result;
      // console.log(items);
      this.tableItems = [];
      for (let i = 0; i < items.length; i++) {
        if (
          this.tableItems.filter((v) => v.orderDataId == items[i].orderDataId)
            .length == 0
        ) {
          this.tableItems.push({
            orderDataId: items[i].orderDataId,
          });
        }
        let tableItems = this.tableItems.find(
          (v) => v.orderDataId == items[i].orderDataId
        );
        tableItems[items[i].fieldChild.id] = items[i].data;
      }

      this.$global.state.loading = false;

      this.$forceUpdate();
    },
    async getResults() {
      this.$global.state.loading = true;
      if (this.selectedField.fieldType.name != "Table") {
        this.options.FieldId = this.selectedField.id;
        this.results = await this.$http.get("/orderData", {
          params: this.options,
        });
        this.results = this.results.data.result;
      } else {
        this.getTableItems();
      }
      this.$global.state.loading = false;
    },
  },
  created() {
    this.$global.dispatch("getField");
    this.$global.dispatch("getWorkFlow");
    this.$global.dispatch("getNodes");
  },
  watch: {
    selectedNode: {
      handler() {
        if (this.selectedField != null) {
          this.setTableHeaders(this.selectedField);
          if (this.selectedField.fieldType.name == "Table") {
            this.getTableItems();
          }
        }
      },
      deep: true,
    },
  },
};
</script>
